@font-face {
  font-family: 'RobotoSlab';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/RobotoSlab.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Ubuntu.ttf');
}

.App {
  background: black;
}

main {
  position: relative;
}

.header {
  padding: 2rem;
  padding-bottom: 8rem;
  background: linear-gradient(rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 1) 90%), url(./assets/earth.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

.web-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 3rem;
  font-weight: 600;
  color: white;
  text-align: center;
}

.country {
  height: 20rem;
  background-color: #201e2ea7;
}

.light-bg {
  background-color: transparent;
}

.country-name {
  font-family: 'RobotoSlab', sans-serif;
}

.capital-name {
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}

.country-name,
.capital-name {
  text-decoration: none;
  color: beige;
}

.country-container {
  margin-top: 6rem;
}

.flag {
  width: 10rem;
}

.back-icon {
  width: 2.5rem;
}

.search-container {
  position: absolute;
  left: 5px;
  top: 1.2rem;
  width: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search-container::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.result-box {
  display: none;
  background-color: #262626ec;
  overflow: scroll;
  list-style: none;
  padding: 10px;
  max-height: 200px;
  z-index: 9;
}

.result-box::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.input {
  width: 100%;
  height: 3.2rem;
  background-color: #201e2ea7;
  padding: 5px;
  border: solid 0.5px #ffffff89;
  outline: none;
  caret-color: white;
  color: white;
}

.input::placeholder {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

.province:nth-child(odd) {
  background-color: #171523bb;
}

.province:nth-child(even) {
  background-color: #141f2db7;
}

@media (max-width: 768px) {
  .search-container {
    width: 20rem;
    right: 20%;
  }

  .light-bg {
    background-color: #2a2a2aa9;
  }
}
